<template>
  <FocusTrap>
    <div class="card" id = "mycard">

      <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Doctor's List</h5>

        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>

        </div>
      </div>


      <div class="table-responsive">
        <table id="doctor-table"
               class="table table-bordered table-columned"
               data-search="false"
               data-pagination="true"
               data-page-size="All"
               data-show-refresh="false"
               data-show-columns="false"
               data-page-list="[10, 25, 50, 100, ALL]"
               data-show-footer="false"
               data-toggle="context"
               data-target=".context-table">
          <thead>
          <tr>
            <th data-field="id" data-class="d-none">id</th>
            <th data-checkbox="true"></th>
            <th data-formatter="runningFormatter" data-width="100">S.No</th>
            <th data-field="name" >Name</th>
            <th data-field="status" >Status</th>
            <th data-field="city" >City</th>
            <th data-field="area.name" >Area</th>
          </tr>
          </thead>
          <tbody>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> <i class="icon-square-up-right text-green" ></i>Enable</a>
          <a href="#" class="dropdown-item"> <i class="icon-blocked text-danger" ></i>Disable</a>
        </div>
      </div>
      <!-- End of Context Menu -->

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
            <i class="fab-icon-open icon-plus3"></i>
            <i class="fab-icon-close icon-plus3"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <FocusTrap>
        <vmodal name="doctor_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="77%" height="90%" @before-open="beforeOpen" @before-close="beforeClose" >
          <DoctorForm v-bind:mydoctor="doctor" v-on:doctor_window_closed="closeModal" v-on:doctor_saved="loadData" ></DoctorForm>
        </vmodal>
      </FocusTrap>

    </div>
  </FocusTrap>
</template>

<script>
import DoctorForm from './DoctorForm';
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'DoctorView',
  store,
  components: {
    DoctorForm
  },
  data () {
    return {
      mytable: {},
      doctor: JSON.parse('{"id":0,"status":"Active","name":"","hospital":"","address":" ","area_id":1,"qualification":"","speciality":"","class":"","dob":"","dow":"","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":0,"name":""},"categories":[]}'),
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    this.$data.mytable = $('#doctor-table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    this.$data.mytable.contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyDocument (id);
        } else if ($(e.target).text() === 'Remove') {
          self.removeDocument(id);
        } else if ($(e.target).text() === 'Disable') {
          //self.disableMedical(id);
          self.disableAll();
        } else if ($(e.target).text() === 'Enable') {
          //self.enableMedical(id);
          self.enableAll();
        }
      }
    });

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase()
      $('#doctor-table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
        return !~text.indexOf(val)
      }).hide();
    });

    self.loadData();
  },
  methods: {
    closeModal () {
      this.$modal.hide('doctor_window');
    },
    showModal () {
      this.$modal.show('doctor_window');
    },
    beforeOpen(){
      console.log('beforeOpen');
    },
    beforeClose(){
      // this.loadData();
      this.doctor = JSON.parse('{"id":0,"status":"Active","name":"","hospital":"","address":" ","area_id":1,"qualification":"","speciality":"","class":"","dob":"","dow":"","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":0,"name":""},"categories":[]}');
    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      this.$modal.hide('doctor_window');
      self.$data.mytable.bootstrapTable('load',[]);

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.mytable.bootstrapTable('showLoading');

      fetch(`${process.env.VUE_APP_ROOT_API}api/doctors`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        self.$data.mytable.bootstrapTable('hideLoading');
        self.$data.mytable.unblock();
      });
    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      self.$data.doctor = JSON.parse('{"id":0,"status":"Active","name":"","hospital":"","address":" ","area_id":1,"qualification":"","speciality":"","class":"","dob":"","dow":"","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":0,"name":""},"categories":[]}');

      // alert(`${process.env.VUE_APP_ROOT_API}api/doctor/${id}`);
      fetch(`${process.env.VUE_APP_ROOT_API}api/doctor/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.doctor = resp.data;
          self.$modal.show('doctor_window');
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
      });
    },
    removeDocument (id) {
      const self = this;

      self.$data.doctor.id = id;

      const requestOptions = {
        method: 'DELETE',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.doctor)
      };

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete Doctor!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/doctor/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('doctor_window')
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            );
          }).catch(function (err) {
            swal({ title: "Oops", text: err.toString(), type: "error" });
          });
        }
      });
    },
    enableDoctor (id) {
      const self = this;

      self.$data.doctor.id = id;

      const requestOptions = {
        method: 'PUT',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.doctor)
      };

      this.$modal.hide('doctor_window');
      self.$data.mytable.bootstrapTable('load',[]);

      fetch(`${process.env.VUE_APP_ROOT_API}api/doctor/status/enable/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {

          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer: 3000} );
          self.loadData();
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      });

    },
    disableDoctor (id) {
      const self = this;

      self.$data.doctor.id = id;

      const requestOptions = {
        method: 'PUT',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.doctor)
      };

      this.$modal.hide('doctor_window');
      self.$data.mytable.bootstrapTable('load',[]);

      fetch(`${process.env.VUE_APP_ROOT_API}api/doctor/status/disable/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {

          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer: 3000} );
          self.loadData();
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      });

    },
    disableAll(){
      const self = this;

      // alert('getSelections: ' + JSON.stringify(this.$data.mytable.bootstrapTable('getSelections')));

      const ids = [];
      this.$data.mytable.bootstrapTable('getSelections').forEach((doctor)=>{
        ids.push(doctor.id);
      });

      const requestOptions = {
        method: 'PUT',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(ids)
      };

      // alert(JSON.stringify(ids));

      this.$modal.hide('doctor_window');
      self.$data.mytable.bootstrapTable('load',[]);

      fetch(`${process.env.VUE_APP_ROOT_API}api/doctor/status/disable_all`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {

          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer: 3000} );
          self.loadData();
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      });

    },
    enableAll(){
      const self = this;

      // alert('getSelections: ' + JSON.stringify(this.$data.mytable.bootstrapTable('getSelections')));

      const ids = [];
      this.$data.mytable.bootstrapTable('getSelections').forEach((doctor)=>{
        ids.push(doctor.id);
      });

      const requestOptions = {
        method: 'PUT',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(ids)
      };

      // alert(JSON.stringify(ids));

      this.$modal.hide('doctor_window');
      self.$data.mytable.bootstrapTable('load',[]);

      fetch(`${process.env.VUE_APP_ROOT_API}api/doctor/status/enable_all`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {

          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer: 3000} );
          self.loadData();
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      });

    },
  }
}
</script>

<style scoped>

</style>
